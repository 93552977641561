import React, { useState } from 'react';
import axios from 'axios';

function Checkout({ cart }) {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('');
  const totalAmount = cart.reduce((total, item) => total + item.price, 0);

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Create payment link with PayMongo
    try {
      const options = {
        method: 'POST',
        url: 'https://api.paymongo.com/v1/links',
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          authorization: 'Basic c2tfdGVzdF9TRGdGSGZtTUxkUm1uWkRzdG5jaW9OU0E6' // Replace with your PayMongo secret key
        },
        data: {
          data: {
            attributes: {
              amount: totalAmount * 100, // Convert to cents
              description: 'Your purchase from the store',
              remarks: `Payment from ${name} | Email: ${email} | Address: ${address}`,
            }
          }
        }
      };

      const response = await axios.request(options);
      console.log('Payment Link Response:', response.data); // Log full response data

      // Extract the checkout URL
      const checkoutUrl = response.data.data.attributes.checkout_url;

      if (checkoutUrl) {
        // Redirect to the checkout URL
        window.location.href = checkoutUrl;
      } else {
        console.error('Checkout URL is not available.');
      }
    } catch (error) {
      console.error('Error creating payment link:', error.response ? error.response.data : error.message);
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
      <h2>Checkout</h2>
        <div>
          <label>Name:</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Email:</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Address:</label>
          <input
            type="text"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            required
          />
        </div>
        <h3>Total: ₱{totalAmount}</h3>
        <button type="submit">Proceed to Payment</button>
      </form>
    </div>
  );
}

export default Checkout;
