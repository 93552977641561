import React, { useState } from 'react';

function Product({ product, addToCart }) {
  const [showNotification, setShowNotification] = useState(false);
  const [fade, setFade] = useState(false);

  const handleAddToCart = () => {
    addToCart(product);
    setShowNotification(true);
    setFade(true);

    // Hide the notification after 2 seconds
    setTimeout(() => {
      setFade(false);
      // Remove the notification completely after the fade out
      setTimeout(() => {
        setShowNotification(false);
      }, 100); // Match this timeout with fade-out duration
    }, 500);
  };

  return (
    <div className="product">
      <h3>{product.name}</h3>
      <img src={product.img} alt="" />
      <p>Price: ₱{product.price}</p>
      <button onClick={handleAddToCart}>Add to Cart</button>

      {/* Notification with fade effect */}
      {showNotification && (
        <div className={`notification ${fade ? 'fade-in' : 'fade-out'}`}>
          Item added to cart!
        </div>
      )}
    </div>
  );
}

export default Product;
