import React from 'react';
import { Link } from 'react-router-dom';

function Cart({ cart, removeFromCart }) {
  const totalAmount = cart.reduce((total, item) => total + item.price, 0);

  return (
    <div>
      <h2>Your Cart <span>({cart.length})</span></h2>
      {cart.length === 0 ? (
        <p>The cart is empty.</p>
      ) : (
        <ul>
          {cart.map((item, index) => (
            <li key={index}>
              {item.name} - ₱{item.price}
              <button onClick={() => removeFromCart(item.id)}>Remove</button>
            </li>
          ))}
        </ul>
      )}
      <h3>Total: ₱{totalAmount}</h3>
      
      {/* Wrap the button with Link properly */}
      <Link to="/checkout">
        <button disabled={cart.length === 0}>
          Proceed to Checkout
        </button>
      </Link>
    </div>
  );
}

export default Cart;
