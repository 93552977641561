import React, { useState } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';

function PaymentGateway() {
  const location = useLocation();
  const { amount } = location.state || { amount: 0 }; // Get the amount passed from Checkout

  const [paymentInfo, setPaymentInfo] = useState({
    name: '',
    email: '',
    amount: amount,
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPaymentInfo({ ...paymentInfo, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      // 1. Create a payment intent
      const { data } = await axios.post(
        'https://api.paymongo.com/v1/payment_intents',
        {
          data: {
            attributes: {
              amount: paymentInfo.amount * 100, // Convert to cents
              currency: 'PHP',
              payment_method_types: ['gcash'], // Change this based on your needs
              payment_method_allowed: ['gcash'], // Added this field
              // Uncomment and add payment_method if you already have it
              // payment_method: 'your_payment_method_id', // If you already have a payment method ID
            },
          },
        },
        {
          headers: {
            'Authorization': `Basic ${btoa('sk_test_SDgFHfmMLdRmnZDstncioNSA')}`, // Replace with your PayMongo Secret Key
            'Content-Type': 'application/json',
          },
        }
      );

      // 2. Confirm payment with PayMongo
      const paymentIntent = data.data.id;
      alert(`Payment Intent Created: ${paymentIntent}`);
    } catch (err) {
      // Log the full error response
      console.error('Payment error:', err.response ? err.response.data : err);
      setError(err.response?.data?.errors[0]?.detail || 'Payment failed. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <h2>Payment Gateway</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Name:</label>
          <input type="text" name="name" value={paymentInfo.name} onChange={handleChange} required />
        </div>
        <div>
          <label>Email:</label>
          <input type="email" name="email" value={paymentInfo.email} onChange={handleChange} required />
        </div>
        <div>
          <label>Amount (in PHP):</label>
          <input type="number" name="amount" value={paymentInfo.amount} onChange={handleChange} readOnly />
        </div>
        <button type="submit" disabled={loading}>
          {loading ? 'Processing...' : 'Pay'}
        </button>
        {error && <p style={{ color: 'red' }}>{error}</p>}
      </form>
    </div>
  );
}

export default PaymentGateway;
