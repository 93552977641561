import React from 'react';
import Product from './Product';
import './Style.css'

const products = [
  { id: 1, name: 'Product 1', price: 100, img: 'Products/Shirt.png'},
  { id: 2, name: 'Product 2', price: 200, img: 'Products/Jersey.png'},
  { id: 3, name: 'Product 3', price: 300, img: 'Products/Korea.png'},
];

function ProductList({ addToCart }) {
  return (
    <div>
      <h2 className='producttitle'>Products</h2>
      <div className="product-list">
        {products.map(product => (
          <Product key={product.id} product={product} addToCart={addToCart} />
        ))}
      </div>
    </div>
  );
}

export default ProductList;
