import { Link, useLocation  } from 'react-router-dom';
import { useState } from 'react';
import './Navbar.css'

function Navbar({ cart })
{

    return(
        <div>
            <nav>
            <Link to="/">Products</Link>
            <Link to="/cart">Cart <span>({cart.length})</span> </Link>
            </nav>
            
        </div>
        
    );
};
export default Navbar;